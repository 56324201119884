.app-container{
  display: flex;
  flex-flow: column;
  height: 100%;
}

.app-container .row.header {
  flex: 0 1 auto;
}

.app-container .row.content {
  flex: 1 1 auto;
}

.row {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.App {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 0!important;
}

.floating-alert {
  display: flex;
    align-items: center;
    width: 590px;
    margin: auto;    
    border-radius: 2px;
    position: fixed;
    top: 100px;
    left: 50%;
    margin-left: -295px;
    z-index: 1;
}

.floating-alert.success{
  color: #00B140;
  background-color: #ebf9f0;
  border: 2px solid #00B140;
}

.floating-alert.alert-danger{
  color: #F5222D;
  background-color: #FFEAEA;
  border: 2px solid #F5222D;
}

.floating-alert svg{
  margin-right: 18px;
}

.floating-alert-body {
  font: normal normal bold 14px/18px Roboto;
}

.floating-alert-body .alert-heading{
  text-align: left;
  font: normal normal bold 14px/18px Roboto;
  letter-spacing: 0px;
  color: #292929;
  opacity: 1;  
  margin-bottom: 0;
}

.floating-alert-body p{
  text-align: left;
  font: normal normal normal 14px/18px Roboto;
  letter-spacing: 0px;
  color: #292929;
  opacity: 1;
  margin-bottom: 0;
}

.floating-alert button{
  top: auto !important;
  color: #292929 !important;
  padding-bottom: 15px !important;
}

.floating-alert button span{
  height: 100%;
}

.app-container ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000DE;
  opacity: 1; /* Firefox */
}

.app-container :-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #000000DE;
}

.app-container ::-ms-input-placeholder { /* Microsoft Edge */
color: #000000DE;
}

/* font faces
-------------------------------------------------- */
@font-face {
  font-family: "Roboto-Regular";
  src: url("./fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./fonts/Roboto/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-Black";
  src: url("./fonts/Roboto/Roboto-Black.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./fonts/Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("./fonts/Roboto/Roboto-Light.ttf");
}

@media only screen and 
(max-width: 429px) and (orientation: portrait),
(max-width: 590px) and (orientation: landscape) 
/* for devices more then 590px width in landscape, the desktop style will work fine */
{
  .floating-alert{
    width: 90%;
    margin: unset;
    left: unset;
    right: 5%;
    align-items: flex-start;
  }

  .alert-dismissible .close{
    padding: 0.4rem 1.25rem !important;
  }

  .floating-alert button{
    top: 0 !important;
  }
}