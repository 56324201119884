.home-page-header-container{
    background-image: url(../images/homepage/header-image.png);
    width: 100%;
    height: 57.6vh;
    min-height: fit-content;
    max-height: 563px;
}

.home-page-header{   
    background-color: rgb(50, 37, 94, 0.5);
    height: 57.6vh;
    min-height: fit-content;
    max-height: 563px;
    text-align: center;
    align-content: center;
    display: grid;
    color: #fff;
    font-family: Roboto-Bold; 
}

.home-page-title{      
    font-size: 3.12vmax;
    margin-bottom: 2vmax;
}

.home-page-title-description{
    word-break: break-word;
    font-size: 1.55vmax;
    margin: 0px 12vw;
    line-height: 2vmax;
}

.header-bottom-container{
    background-color: #32255E;
    background-image: url(../images/homepage/solera-symbols-banner.png);
    height: 18vmax;
    color: #fff;
    font-size: 1vmax;
    font-family: 'Roboto-Regular';
    padding: 0 15.5vw;
    display: inline-flex;
    text-align: center;
    align-items: center;
}

.header-bottom-sections{
    width: 33%;
    height: fit-content;
}

.header-bottom-container img{
    margin-bottom: 1.5vw;
    height: 4.7vmax;
}

.header-bottom-container p{
    margin: 0 2.2vw;
}

.home-page-body-container{
    margin: 4.3vmax auto 0px auto;
    padding: 0 5%;
    width: fit-content;
    justify-content: center;
    display: grid;
}

.home-page-body-container img{
    width: 100%;
    height: auto;
}

.location-details-description{
    column-count: 5;
    column-gap: 2px;
    width: fit-content;
}

.location-details-description-sections{
    width: fit-content;
    position: relative;
}

.location-details-description-sections .default-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;    
    overflow: hidden;
    width: 100%;
    height: 3.75vmax;
    transition: .4s ease .2s;
    background-color: rgb(0, 0, 0, 0.5);
}

.bottom-border-address-contact, .bottom-border-servicing{
    box-shadow: 0px -1vmax 0px #32255E inset;
}

.bottom-border-amenities{
    box-shadow: 0px -1vmax 0px #68368B inset;
}

.bottom-border-geofence{
    box-shadow: 0px -1vmax 0px #194FA1 inset;
}

.bottom-border-visit-trends{
    box-shadow: 0px -1vmax 0px #1A99B0 inset;
}
  
.location-details-description-sections .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;    
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}
  
.location-details-description-sections:hover .overlay {
    height: 100%;
}

.location-details-description-sections:hover .default-overlay {
    height: 0;
    transition: .1s ease .0s;
}

.address-contact, .servicing{
    background-color: rgb(50, 37, 94, 0.5);
}

.amenities{
    background-color: rgb(104, 54, 139, 0.5);
}

.geofence{
    background-color: rgb(25, 79, 161, 0.5);
}

.visit-trends{
    background-color: rgb(26, 153, 176, 0.5);
}

.location-details-description-sections .text {
    color: white;
    position: relative;
    top: 50%;
    left: 50%;
    height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: left;
    padding: 0.8vmax 1.6vw 0.8vmax 0.9vw;
    line-height: 1;
    align-content: end;
    display: grid;
}

.text-title{
    font-size: 1.6vmax;
    font-family: 'Roboto-Bold';
}

.text-description{
    font-size: 0.95vmax;
    font-family: 'Roboto-Regular';
    margin-bottom: 0.75vmax;
}

.default-overlay .text{
    line-height: unset;
}

.customer-types-container{
    margin-top: 5.6vmax;
}

.customer-types-container .MuiTabs-flexContainer{
    column-gap: 14px;
}

.customer-types-container button{
    background-color: #C1C1C1;
    width: 11.6vw;
    height: 4.1vw;
    min-height: 38px;
}

.customer-types-container button span{
    color: #424242;
    text-transform: capitalize;
    font-size:  1.25vw;
    font-family: 'Roboto-Regular' !important;
    font-weight: normal;
    border-bottom: none;
}

.customer-types-container button[aria-selected="true"]{
    background-color: #194FA1;
}

.customer-types-container button[aria-selected="true"] span{
    color: #fff;
}

.customer-types-container .MuiTabs-indicator{
    display: none;
}

.customer-type-tab-body{
    display: flex;
    justify-content: space-evenly;
    margin-top: 4.2vmax;
}

.customer-type-tab-text{
    width: 50%;
    max-width: 650px;
    color: #424242;
    margin-top: -15px;
    margin-right: auto;
    padding-right: 2%;
    display: inline-block;
}

.tab-text-title{
    font-size: 2.5vw;
    font-family: 'Roboto-Bold';
    margin: 1.5vmax 0 2vmax 0
}

.tab-text-description{
    font-family: 'Roboto-Regular';
    overflow-wrap: break-word;
    font-size: 1.25vmax;
    line-height: 1.5vmax;
    margin-bottom: 1.25vmax;
}

/* CSS for Mobile Portrait mode */
@media only screen and 
(max-width: 428px) and (orientation: portrait){
    .home-page-header-container{
        background-image: url(../images/homepage/header-image-mobile-portrait.png);
        height: 125vw;
        max-height: 481px;
    }

    .home-page-header{
        padding: 35px 24px 0 24px;
        text-align: left;
        align-content: unset;   
        height: 125vw;
        max-height: 481px;
        background-color: rgb(50, 37, 94, 0.7);
    }

    .home-page-title{
        font-size: 10.2vw;
        line-height: 12.3vw;
        margin-bottom: -3vmax;
    }

    .home-page-title-description{
        font-size: 4.8vw;
        line-height: 7.5vw;
        font-family: 'Roboto-Regular';
        margin: 0;
    }

    .header-bottom-container{
        background-color: #fff;
        color: #444854;
        font-size: 4.8vw;
        line-height: 5.5vw;
        padding: 0 5%;
        height: fit-content;
        display: block;
    }
    
    .header-bottom-sections{
        width: 100%;
        margin-top: 7vh;
    }
    
    .header-bottom-container img{
        margin-bottom: 2.8vh;
        height: 21.5vw;
        filter: brightness(0) saturate(100%) invert(22%) sepia(25%) saturate(6063%) hue-rotate(206deg) brightness(92%) contrast(88%);
    }
    
    .home-page-body-container-mobile{
        padding: 0 5%; 
        margin-top: 12%; 
        color: #444854;      
    }

    .home-page-body-container-mobile img{
        max-width: 100%;
        height: auto;
        margin-bottom: 7%;
    }

    .location-details-description-mobile{
        margin-bottom: 40px;
        max-width: 343px;
        margin-left: auto;
        margin-right: auto;
    }

    .text-title{
        font-size: 7.5vw;
        font-family: 'Roboto-Light';
        line-height: 4.3vh;
    }

    .text-description{
        font-size: 4.6vw;
        line-height: 5.6vw;
    }

    .customer-types-container .MuiTabs-flexContainer{
        column-gap: 5px;
        justify-content: space-evenly;
        margin-top: 2%;
    }

    .customer-types-container button{
        width: 31%;
        padding: 0;   
    }

    .customer-types-container button span{
        font-size:  3.3vw;
    }

    .customer-type-tab-body{
        flex-direction: column-reverse;
        grid-row-gap: 15px;
    }

    .customer-type-tab-text{
        width: 100%;
    }

    .tab-text-title{
        font-size: 6vw;
    }

    .tab-text-description{
        font-size: 4.7vw;
        line-height: 3.5vh;
        margin-bottom: 2vh;
    }

}

/* CSS for Tablets and Mobile Landscape mode */
@media only screen and
(min-width: 768px) and (max-width: 1023px) and (orientation: portrait), 
(max-width: 1279px) and (orientation: landscape){
    .customer-type-tab-text{
        min-width: 50%;
        padding-right: 4.2vmax;
    }

    .customer-types-container button{
        min-width: 120px;
    }

    .customer-types-container button span{
        font-size:  12px;
    }

    .home-page-header-container, .home-page-header{
        height: 28.5vmax;
    }
}

/* CSS for Tablets Portrait only */
@media only screen and 
(min-width: 429px) and (max-width: 1023px) and (orientation: portrait){

    .header-bottom-container{
        padding: 0 4vw;
    }

    .home-page-body-container{
        padding: 0 1.5%;
    }

    .text-description{
        font-size: 12px;
    }
}