.currency-container input{
    padding: 0 5rem 0 1.6rem !important;
    letter-spacing: 0.1px;
    font-family: 'Roboto-Medium';
}

.currency-icon-container{
    left: 3%;
    width: fit-content;
    position: absolute;
    top: 14%;
}

.currency-icon-container svg{
    height: 1.4rem;
    width: 1.05rem;
}

.currency-type-container{
    width: 80px;
    position: absolute;
    top: 7%;
    left: 68%;
}

.currency-type-container .floating-label{
    width: inherit !important;
}

.currency-type-container select{
    border: none !important;
    font-family: 'Roboto-Medium';
    background-image: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%), linear-gradient(to right, white, white);
}

@media only screen and
(max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape){
    .currency-type-container{
        width: 72px;
        left: 75%;
        top: 8%;
    }

    @media  only screen and 
    (max-width: 360px) and (orientation: portrait),
    (max-width: 702px) and (orientation: landscape) {
        .currency-type-container{
            left: 68%;
        }
    }
}

