.company-details-container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);
    opacity: 1; 
    width: 100%;
    padding: 12% 5% 5%;
    margin-bottom: 7%;
}

.company-details-item{
    width: 100%;
    margin-bottom: 15%;
    margin-left: 2%;
    font-size: 14px;
    vertical-align: middle;
    word-spacing: 1px;
}

.icon{
    float: left;
}

.company-details{
    margin-left: 14%;
    line-height: 1.3rem;
}

.address{
    text-transform: capitalize;
}

.label{
    font-family: Roboto-Bold;
    margin-bottom: 0.1rem;
}

a{
    color: #215BA6;
}