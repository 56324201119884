.Admin-Portal-Container {
  max-width: 90%;
  padding-top: 45px;
  margin: auto;
}

.tab-panel-header {
  display: inline-flex;
  font: normal normal 300 32px/38px Roboto;
  padding-bottom: 40px;
}

.tab-nav {
  margin-left: 50px;
  display: inline-flex;
  background: #EBEBEB;
  border-radius: 4px;
}

.tab-nav-tabs {
  font: normal normal normal 14px/24px Roboto;
}

.tab-nav .nav-link {
  padding: 8px 16px 6px 16px !important;
  color: #000000 !important;
}

.tab-nav .nav-link:hover {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

.tab-nav .nav-link.active {
  background: #FFFFFF;
  border-radius: 4px;
}

.table-search-header {
  background: #FFFFFF;
  width: 100%;
  height: 75px;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.search-box {
  font: normal normal normal 14px/20px Roboto !important;
  background: transparent;
  height: fit-content;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-right: 30px;
  width: 210px !important;
}

.search-box span {
  background: transparent;
  border: none;
}

.search-box .form-control {
  border: none;
  box-shadow: none;
  color: #000000DE;
}

.search-box:focus-within {
  border: 2px solid #007EB5;
  border-radius: 4px;
  box-shadow: none;
}

.search-button,
.toggle-button {
  background-color: transparent;
  font: normal normal bold 14px/18px Roboto;
  border-radius: 20px;
  border-color: #215BA6;
  color: #215BA6;
  text-transform: capitalize;
  padding: 5px 15px;
}

.toggle-button {
  border-radius: 5px;
  height: 40px;
  padding: 8px 10px;
}

.toggle-button svg {
  display: block;
  margin: auto;
  margin-top: -7px;
}

.small-action-button {
  margin: 0 5px;
  border-radius: 30px;
  font: normal normal bold 14px/18px Roboto;
}

.small-action-button.approve{
  background: #32CD32 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  border: none;
  box-shadow: none;
}

.small-action-button.deny{
  background: #F55515 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  border: none;
  box-shadow: none;
}

.small-action-button:disabled {
  background: #EBEBEB 0% 0% no-repeat padding-box;  
  opacity: 1;
  color: #404042;  
  border: none;
  box-shadow: none;
}

.small-action-button.review {
  width: 110px;
  background: #215BA6;
  border-color:#215BA6;
  border: none;
  padding: 8px;
}

.search-button:hover,
.search-button:focus,
.toggle-button:hover,
.toggle-button:focus {
  color: #0B2F7B;
  border-color: #0B2F7B;
  box-shadow: none;
  background-color: transparent;
}

.clear-button {
  margin-left: 10px;
  height: 36px;
  background-color: transparent;
  font: normal normal bold 14px/18px Roboto;
  border: none;
  color: #215BA6;
  text-transform: capitalize;
  padding: 5px 15px;
}

.clear-button:hover,
.clear-button:focus {
  color: #0B2F7B;
  border: none;
  box-shadow: none;
  background-color: transparent;
  text-decoration: none;
}

.clear-button:disabled {
  color: #C8C8C8;
}

.modal-button {
  background: #215BA6;
  border: none;
  border-radius: 20px;
  font: normal normal bold 14px/18px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.modal-button:hover,
.modal-button:focus,
.small-action-button.review:hover,
.small-action-button.review:focus {
  background: #0B2F7B;
  box-shadow: none;
}

.floating-table,
.flex-table {
  border-collapse: separate;
  border-spacing: 0 2px;
}

.floating-table th,
.flex-table th {
  background-color: #EBEBEB;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 150px;
  border-top: 7px solid #FFFFFF;
  border-bottom: 7px solid #FFFFFF !important;
  height: 60px;
}

.floating-table th:first-child,
.flex-table th:first-child {
  border-radius: 8px 0 0 8px;
  border-left: 7px solid #FFFFFF;
  width: 60px;
}

.floating-table th:last-child,
.flex-table th:last-child {
  border-radius: 0 8px 8px 0;
  border-right: 7px solid #FFFFFF;
}

.floating-table td,
.flex-table td {
  width: 150px;
  background-color: #FFFFFF;
  border: none;
  text-align: left;
  font: normal normal normal 14px/24px Roboto;
  letter-spacing: 0.1px;
  opacity: 1;
  height: 75px;
  vertical-align: middle;
}

.floating-table td:first-child {
  border-radius: 8px 0 0 8px;
  padding-left: 30px;
  padding-right: 35px;
  width: min-content;
  max-width: 2rem;
}

.floating-table td:last-child {
  border-radius: 0 8px 8px 0;
}

.floating-table a,
.flex-table a {
  color: #215BA6;
}

.floating-table a:hover,
.flex-table a:hover {
  color: #6E6D6A;
}

.floating-table .moreVerIcon,
.flex-table .moreVerIcon {
  color: #215BA6;
}

.floating-table .moreVerIcon:hover,
.flex-table .moreVerIcon:hover {
  min-width: 25px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-color: #215BA6;
  color: white;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.floating-table .table-content-ellipsis,
.flex-table .table-content-ellipsis {
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.pending-edits .search-type-toggle-wrapper{
  border: 1px solid #C8C8C8;
}

.pending-edits .search-type-toggle:checked + label {
  outline: 2px solid #FFFFFF;
  border: none;
  box-shadow: #0077c348 0px 0px 8px 3px;
}