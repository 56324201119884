.floating-label{
    position: relative;
    height: 100%;
}

    .floating-label input, .floating-label select, .floating-label textarea {
        font-size: 14px;
        font-family: Roboto-Regular;
        /* padding: 10px 16px; */
        height: 100%;
        border: solid 2px #C8C8C8;
        border-radius: 4px;
        background: transparent 0% 0% no-repeat padding-box;
        width: 100% !important;
        box-sizing: border-box;
        transition: all .3s linear;
        color: #2e2e2e;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .floating-label select {
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
        background-image:
            linear-gradient(45deg, transparent 50%, #007EB5 50%),
            linear-gradient(135deg, #007EB5 50%, transparent 50%),
            linear-gradient(to right, white, white);
        background-position:
            calc(100% - 18px) calc(1em),
            calc(100% - 12px) calc(1em),
            calc(100% - 2.5em) 0.5em;
        background-size:
            6px 6px,
            6px 6px,
            1px 1.5em;
        background-repeat: no-repeat;
    }    

        .floating-label input:focus, .floating-label select:focus, .floating-label textarea:focus {
            outline: 0;
            box-shadow: none;
        }

        .floating-label input:not(:placeholder-shown), .floating-label select:not(:placeholder-shown), .floating-label textarea:not(:placeholder-shown) {
            background-color: #ffffff;
        }

        .floating-label input:-internal-autofill-selected, .floating-label select:-internal-autofill-selected, .floating-label textarea:-internal-autofill-selected,
        .floating-label input:-webkit-autofill, .floating-label select:-webkit-autofill, .floating-label textarea:-webkit-autofill {
            background-color: #ffffff;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px #ffffff inset;
 box-shadow: 0 0 0 30px #ffffff inset;
}

        .floating-label input:not(:placeholder-shown) + label, .dropdown-floating-label {
            opacity: 1;
            background-color: #ffffff;
            font-size: 10px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: #6D6D6D;
            padding: 0 2px;
        }
/* hidden-label */
    .textbox-floating-label, .hidden-label {
        font-size: 12.5px;
        color: #000;
        opacity: 0;
        font-weight: 400;
        position: absolute;
        top: calc(50% - 7px);
        left: 50%;
        transition: all .3s ease;
        width: fit-content;
    }


.small-field {
    margin-bottom: -30px;
}

.floating-label textarea{
    margin-bottom: 0px !important;
    max-height: 230px;
    min-height: 230px;
}

/* floating */
.floating-label input:not(:placeholder-shown) + label {
    top: -6px;
    left: 10px;
}

.dropdown-floating-label {
    top: -6px;
    left: 10px;
    width: fit-content;
    position: absolute;
}

.floating-label .error,
.floating-label .error input:-internal-autofill-selected, .floating-label .error select:-internal-autofill-selected,
.floating-label .error input:-webkit-autofill, .floating-label .error select:-webkit-autofill {
    border-color: #F5222D !important;
    background-color: #FFEAEA !important;
    -webkit-box-shadow: 0 0 0 30px #FFEAEA inset;
 box-shadow: 0 0 0 30px #FFEAEA inset;
}

.error.form-text {
    font-size: 12px;
    font-family: Roboto-Regular;
    color: #F5222D;
    padding-left: 16px;
    margin-top: 0;
    margin-bottom: -17px;
    height: 17px;
}

@media only screen and 
  (max-width: 767px) and (orientation: portrait),
  (max-width: 926px) and (orientation: landscape){
    .form-control:focus{
        border-color: #68368B;
    }

    .floating-label input, .floating-label select, .floating-label textarea{
        border: solid 1px #C8C8C8;
    }

    .floating-label input:focus, .floating-label select:focus, .floating-label textarea:focus {
        border: 1px solid #68368B;
    }

    .floating-label input:not(:focus):not(:placeholder-shown) {
        border: 1px solid #292929;
    }
    
    .floating-label select {
        background-image:
        linear-gradient(45deg, transparent 50%, #68368B 50%),
        linear-gradient(135deg, #68368B 50%, transparent 50%),
        linear-gradient(to right, white, white);
        max-height: 100px;
        overflow-y: auto;
    }

    .floating-label select:valid{
        border: solid 1px #000;
    }

    .floating-label textarea{
        max-height: 194px;
        min-height: 194px;
    }
}