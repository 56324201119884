ul {
    list-style-type: none;
    margin: auto;
    padding: 0;
}

li {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin: 2px 0;
}

li > span {
    padding-left: 5px;
    padding-top: 2px;
}

li.valid svg {
    color: green;
}

li.invalid svg {
    color: red;
}