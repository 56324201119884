.rating-title{
    font-family: Roboto-Bold;
    font-size: 16px;
    color: #000000DE; 
}

.rating-description{
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #525252; 
}
.star-rating{
    text-align: center;
    padding-top: 5px;
    width: 100%;
}

.star-rating button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #68368b;
    padding: 1px 4px;
  }
  .on .filled-star {
    display: block;
  }
  .off .filled-star {
    display: none;
  }
  .on .empty-star {
    display: none;
  }
  .off .empty-star {
    display: block;
  }

.star-rating p{
    font-family: Roboto-Regular;
    font-size: 13px;
    color: #6E6D6A;
    padding-top: 10px;
}
