.update-password-container {
    display: inline-block;
    margin: auto;
    margin-top: 65px;
    background-color: #fff;
    padding: 45px;
    border-radius: 4px;
}

.update-password-header {
    font-family: Roboto-Light;
    font-size: 32px;
    text-align: left;
}

.update-password-body{
    text-align: left;
    font: normal normal bold 14px/28px Roboto;
    letter-spacing: 0.07px;
    color: #000000;
    opacity: 1;
    padding: 25px 55px 10px 55px;
}

.update-password-button-container{
    text-align: right;
}

.update-password-button-container button{
    margin-left: 15px;
    width: 100px;
    height: 36px;
}

@media only screen and 
(max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape){

    .update-password-container {
        max-width: 100%;
        margin: 15px 0 0 0;
        padding: 0;
    }

    .update-password-body{
        font-size: 12px;
        padding: 25px 15px 10px 15px;
    }

    .update-password-header {
        font-size: 28px;
        text-align: center;
    }
}