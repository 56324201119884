.company-container{
    margin-top: 3vh;
    margin-bottom: 2vh;
    display: inline-flex;
    justify-content: center;
    column-gap: 2vw;
    height: 72vh;
    width: 100%;
}

.company-info-container{
    position: relative;
    width: 15%;
    min-width: 260px;
  }

  .company-header{
    background-color: #0B2F7B;
    color: #FFFFFF;
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    font-family: Roboto-Medium;
    font-size: 16px;
    padding: 2% 0% 2% 7%;
    word-spacing: 1px;
}

.company-header p{
    margin-bottom: 0 !important;
}

.company-locations-container{
  position: inline-flex;
  width: 66.5%;
  min-width: 960px;
  display: inline-block;
}

@media only screen and (max-height: 768px){
  .company-container{
    height: 68vh;
  }
  .users-container{
    height: 35vh;
  }
}