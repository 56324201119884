.ripple {
    position: relative;
    overflow: hidden;
    float: left;
  }
  .ripple .rippleContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .ripple .rippleContainer span {
    transform: scale(0);
    border-radius: 30%;
    width: 100%;
    height: 100%;
    size: 100%;
    display: block;
    position: absolute;
    opacity: 1;
    background-color: silver;
    animation: ripple 700ms ease-out;
  }
  
  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }