.edit-container {
    margin: 45px auto;
    width: fit-content;
    justify-content: center;
    text-align: left;
    font: normal normal normal 24px/24px Roboto;
    letter-spacing: -0.24px;
    color: #000000;
    opacity: 1;
}

.edit-container-body{
    padding-top: 15px;
    display: flex;
    column-gap: 2vw;
}

.edit-sections{
    flex: 1;
}

.edit-section-box{
    width: 25vmax;
    max-width: 415px;
    height: 56px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E5E5E5;
    border-radius: 7px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 18px/19px Roboto;
    letter-spacing: -0.36px;
    color: #292929;
    opacity: 1;
    line-height: 55px;
    padding-left: 40px;
    margin-bottom: 10px;
    cursor: pointer;
}

.edit-section-box.active{
    color: #215BA6;
    border-left: 8px solid #215BA6;
    padding-left: 32px;
}

.detail-arrow{
    float: right;
    margin: 14px;
}

.hide{
    display: none !important;
}

.edit-details-container{
    background: #FDFDFD;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 7px 7px;
    opacity: 1;
    max-width: 935px;
    width: 62vw;
    word-spacing: 1px;
    padding-bottom: 7%;
}

.edit-details-header{
    background-color: #0B2F7B;
    color: #FFFFFF;
    padding: 1% 0% 1% 3%;
    word-spacing: 1px;
    border-radius: 7px 7px 0px 0px;
}

.edit-details-title{
    font-family: Roboto-Bold;
    font-size: 16px;
    letter-spacing: 0.14px;
}

/* common css */
.edit-details-body{
    padding: 2% 3%;
}

.edit-details-description{
    font-weight: normal;
    color: #525252;
    font-size: 16px;
    line-height: 1.5rem;
}

.edit-details-text-dark{
    color: #000000DE !important;
    font-family: "Roboto-Medium";
}

.label-description{
    font-size: 14px;
    color: #525252DE;
}

.edit-details-body-padding{
    padding-top: 1%;
    padding-bottom: 1%;
}

.edit-details-container .floating-label input:focus{
    border-color: #215BA6;
}

.text-area-container{
    width: 65%;
    height: 100%;
    margin-top: 1%;
}

.access-denied{
    font: normal normal normal 18px Roboto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
}