.location-details-label {
    font-family: Roboto-Bold;
    font-size: 18px;
    color: #292929;
}

.location-details-icon {
    margin-right: 15px;
    width: 20px;
}

.location-details-icon img {
    vertical-align: top;
}

.location-details-body {
    font-family: Roboto-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.26px;
    text-align: left;
    color: #525252;
}

.location-details-body p {
    margin: 0;
}

.location-details-nodata {
    font-family: Roboto-Bold;
    font-size: 12px;
    color: #6E6D6A;
}

.business-hours-title,
.contacts-title,
.sub-title {
    font-family: Roboto-Medium;
    font-size: 14px;
    color: #000000;
    text-decoration: underline;
}

.business-hours-container{
    width: inherit;
}

.business-hours-days-container {
    font-family: Roboto-Regular;
    font-size: 14px;
    color: #292929;
    padding-bottom: 15px;
}

.business-hours-day-row,
.contact-info-row {
    display: inline-flex;
    width: 100%;
}

.business-hours-day {
    width: 40%;
}

.location-details-closures {
    font-family: Roboto-Medium;
    font-size: 14px;
    color: #000000;
}

.contact-container {
    padding-bottom: 15px;
}

.contact-container:last-child {
    padding-bottom: 5px;
}

.contact-icon {
    margin-right: 10px;
}

.contact-icon,
.contact-icon img {
    height: 15px;
    width: 15px;
}

.location-details-closures {
    font-family: Roboto-Medium;
    font-size: 14px;
    color: #000000;
}

.amenity-label {
    font-family: Roboto-Regular;
    font-size: 12px;
    width: 20px;
}

.amenity-img {
    padding-left: 15px;
    float: right;
    margin-top: 3%;
}

.amenity-label-yes,
.amenity-label-no,
.amenity-label-null {
    font-family: Roboto-Regular;
    font-size: 14px;
}

.amenity-label-yes {
    color: #00B140;
}

.amenity-label-no {
    color: #EE160D;
}

.amenity-label-null {
    color: #525252;
}

.appointment-container {
    margin-top: 8%;
    width: inherit;
}

.sub-container {
    margin-top: 6%;
}

.appointment-method-title {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
}

@media only screen and (max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape) {
    .location-details-label {
        font-size: 14px;
    }

    .location-details-body {
        font-size: 13px;
    }

    .location-details-icon {
        padding-right: 5px;
    }

    .business-hours-title,
    .contacts-title,
    .sub-title,
    .business-hours-days-container,
    .location-details-closures {
        font-size: 13px;
    }

    .business-hours-day {
        font-family: Roboto-Medium;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.26px;
        text-align: left;
        color: #292929;
    }

    .amenity-label-yes,
    .amenity-label-no,
    .amenity-label-null {
        font-family: Roboto-Regular;
        font-size: 13px;
    }

    .business-hours-day {
        width: 28vmin;
    }
}