.fixed-label {
    position: relative;
    height: 100%;
}

.textbox-fixed-label, .dropdown-fixed-label {
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0.24px;
    color: #515151;
    opacity: 1;
    margin-bottom: 5px;
}

.fixed-label input,
.fixed-label select,
.fixed-label textarea {
    font-size: 14px;
    font-family: Roboto-Regular;
    /* padding: 10px 16px; */
    border: solid 2px #C8C8C8;
    border-radius: 4px;
    background: transparent 0% 0% no-repeat padding-box;
    width: 100% !important;
    box-sizing: border-box;
    transition: all .3s linear;
    color: #2e2e2e;
    font-weight: 400;
    margin-bottom: 10px;
}

.fixed-label select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    background-image:
        linear-gradient(45deg, transparent 50%, #5B5A5D 50%),
        linear-gradient(135deg, #5B5A5D 50%, transparent 50%),
        linear-gradient(to right, white, white);
    background-position:
        calc(100% - 18px) calc(1em),
        calc(100% - 12px) calc(1em),
        calc(100% - 2.5em) 0.5em;
    background-size:
        6px 6px,
        6px 6px,
        1px 1.5em;
    background-repeat: no-repeat;
}

.fixed-label input:focus,
.fixed-label select:focus,
.fixed-label textarea:focus {
    outline: 0;
    box-shadow: none;
}

.fixed-label input:not(:placeholder-shown),
.fixed-label select:not(:placeholder-shown),
.fixed-label textarea:not(:placeholder-shown) {
    background-color: #ffffff;
}

.fixed-label input:-internal-autofill-selected,
.fixed-label select:-internal-autofill-selected,
.fixed-label textarea:-internal-autofill-selected,
.fixed-label input:-webkit-autofill,
.fixed-label select:-webkit-autofill,
.fixed-label textarea:-webkit-autofill {
    background-color: #ffffff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset;
    box-shadow: 0 0 0 30px #ffffff inset;
}



.small-field {
    margin-bottom: -30px;
}

.fixed-label textarea {
    margin-bottom: 0px !important;
    max-height: 230px;
    min-height: 230px;
}

/* floating */
.fixed-label input:not(:placeholder-shown)+label {
    top: -6px;
    left: 10px;
}

.fixed-label .error,
.fixed-label .error input:-internal-autofill-selected,
.fixed-label .error select:-internal-autofill-selected,
.fixed-label .error input:-webkit-autofill,
.fixed-label .error select:-webkit-autofill {
    border-color: #F5222D !important;
    background-color: #FFEAEA !important;
    -webkit-box-shadow: 0 0 0 30px #FFEAEA inset;
    box-shadow: 0 0 0 30px #FFEAEA inset;
}

.error.form-text {
    font-size: 12px;
    font-family: Roboto-Regular;
    color: #F5222D;
    padding-left: 16px;
    margin-top: 0;
    margin-bottom: -17px;
    height: 17px;
}

@media only screen and (max-width: 767px) and (orientation: portrait),
(max-width: 926px) and (orientation: landscape) {
    .form-control:focus {
        border-color: #68368B;
    }

    .fixed-label input,
    .fixed-label select,
    .fixed-label textarea {
        border: solid 1px #C8C8C8;
    }

    .fixed-label input:focus,
    .fixed-label select:focus,
    .fixed-label textarea:focus {
        border: 1px solid #68368B;
    }

    .fixed-label input:not(:focus):not(:placeholder-shown) {
        border: 1px solid #292929;
    }

    .fixed-label select {
        background-image:
            linear-gradient(45deg, transparent 50%, #68368B 50%),
            linear-gradient(135deg, #68368B 50%, transparent 50%),
            linear-gradient(to right, white, white);
        max-height: 100px;
        overflow-y: auto;
    }

    .fixed-label select:valid {
        border: solid 1px #000;
    }

    .fixed-label textarea {
        max-height: 194px;
        min-height: 194px;
    }
}