.pending-edits-modal-container{
    max-width: 102rem;
    width: 95%;
    height: 92%;
    margin-top: 2.5rem;
    margin-bottom: 0;
    overflow: hidden;
}

.pending-edits .modal-header{
    height: 42px;
    padding: 0.35rem 0.75rem;
}

.pending-edits .modal-content{
    height: 95%;
    background-color: #f2f3f4;
}

.pending-edits .modal-body{
    padding: 0;
    margin-bottom: 30px;
}

.pending-edits .edit-header{
    padding: 0.9rem 3rem;
}

.pending-edits .spinner-container{
    top: 30%;
    z-index: 9999 !important;
}

.pending-edits-container-body{
    position: absolute;
    width: fill-available;
    max-height: fill-available;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 4px;
}

/* starts - scrollbar css */
    .pending-edits-container-body::-webkit-scrollbar {
        width: 4px;
        margin-top: 4px;
    } 
    /* Track */
    .pending-edits-container-body::-webkit-scrollbar-track {
        background: #f2f3f4;
        margin-top: 10px;
        margin-bottom: 10px;
    } 
    /* Handle */
    .pending-edits-container-body::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 7px;
    }
    /* Handle on hover */
    .pending-edits-container-body::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }
/* ends - scrollbar css */

.pending-edits .edit-container{
    margin: 0.8rem auto;
}

.pending-edits .edit-section-box{
    height: 54px;
    line-height: 54px;
    width: 340px;
}

.edits-count{
    width: fit-content;
    min-width: 26px;
    height: 26px;
    padding: 6px;
    color: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 14px;
    background-color: #68368B;
    display: inline-flex;
    font-size: small;
    font-weight: 500;
    float: right;
}

.location-pending-edits-table{
    background-color: #f2f3f4;
    font-size: 15px;
    width: 100%;
}

.pending-edits .edit-details-container{
    max-width: unset;
    width: 67rem;
    padding-bottom: 0;
}

.pending-edits .edit-details-header{
    font-size: 1.3rem;
    padding: 9px 3%;
}

.pending-edits-body-margin{
    margin-top: 20px;
}

/* starts original-data specific css */
.original-data-body{
    margin-top: 10px;
}
.pending-edits .original-data-body:not(.services-edits):not(.contacts-edits) .location-details-body div{
    margin: 0;
    padding-top: 0 !important;
}
.pending-edits .original-data-body .location-details-body{
    display: flex;
    flex-wrap: wrap;
    column-gap: 4%;
    row-gap: 10px;
    width: 100%;
}
.original-data-text{
    margin-top: 3px;
    font-size: 16px;
}
.original-data-body .contact-container,
.original-data-body .business-hours-container{
    width: fit-content;
    max-width: 25%;
}
.original-data-body .contact-container p{
    word-wrap: break-word;
    max-width: 220px;
}
/* ends original-data specific css */

.pending-edits .location-details-icon, 
.pending-edits .location-details-label, 
.pending-edits .floating-table .contacts-title,
.pending-edits .floating-table .business-hours-title{
    display: none;
}

.pending-edits .amenities-edits .amenity-img{
    margin: 0;
}

.pending-edits .appointment-edits .location-details-body div{
    min-width: 200px;
    max-width: 30%;
}

.pending-appointment-edits,
.pending-safety-edits{
    padding-top: 5px !important;
    margin-top: 0;
}
.pending-appointment-edits .location-details-body{
    display: unset;
}

.pending-edits .floating-table{
    background-color: #f2f3f4;
    padding: 2px 4px;
    margin-top: 2px;
}

.edits-table-th-first{
    padding-left: 22px !important;
    
}

.edited-content-type{
    word-wrap: break-word;
    max-width: 130px;
}

.edited-content{
    word-wrap: break-word;
    max-width: 250px;
    min-width: 200px;
}

.edits-table-td-first{
    max-width: 220px !important;
    padding-right: 15px !important;
    word-wrap: break-word;
}

.edits-table-content-align-top td{
    vertical-align: top;
    padding-top: 15px;
}

.pending-edits .MuiToggleButtonGroup-root{
    margin: 0;
}
.pending-edits .MuiToggleButtonGroup-root button {
    padding: 8px 0;
}

.approve-deny-all-container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-right: 3%;
}

.approve-deny-all-container .option-check-container{
    margin-bottom: 18px;
}

.approve-deny-all-container .MuiToggleButtonGroup-root:not(:has(.Mui-disabled)){
    border: 1px solid #68368B !important;   
}

.approve-deny-all-container .MuiToggleButtonGroup-root:not(:has(.Mui-selected)) button:not(:disabled){
    color: #68368B !important;
}

/* Starts Accordion CSS for Pending Edits */
.pending-edits .MuiAccordion-root{
    border: solid 2px #f2f3f4;
}
.pending-edits .MuiAccordionDetails-root {
    padding: 0;
    display: block;
}
.pending-edits .MuiAccordion-root .floating-table{
    margin: 0;
    padding: 2px;
}
.pending-edits .MuiPaper-elevation1 {
    
    box-shadow: #f2f3f4 0px 0px 5px 2px;
}
.pending-edits .MuiAccordion-root:before {
    display: none;
}
.pending-edits .MuiIconButton-root{
    color: #68368B;
}
/* Ends Accordion CSS for Pending Edits */