@media only screen and 
(max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape) {
    
    .login-nav, .main-nav, .home-nav{
        padding: 0% 24px !important;
        border-top: solid 2px #68368B;
        height: 70px;    
    }

    .navbar-brand{
        padding: 0;
        margin: 0;
        width: 85%;
    }

    .navbar-brand img{
        width: 100%;
        max-width: 350px;
    }
    
    .navbar-toggler{
        width: 15%;
        border: 0;
        padding-right: 0;
        justify-content: flex-end;
        display: inline-flex;
    }
    
    .navbar-light .navbar-toggler-icon {
        background-image: url(../images/menu.svg);
        height: 17px;
        width: 24px;
    }

    .collapsing{
        transition: none;
    }
  
    .navbar-items-container {
        padding: 0 !important;
        margin-left: -24px;
        z-index: 2;
        position: fixed;  
        top: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: brightness(0.8);
        justify-content: unset !important;
    }
  
    .navbar-items{
        background-color: #68368B;
        width: 85%;
        height: 100%;
        bottom: 0;
        flex-direction: column !important;
    }
  
    .nav-link, .nav-non-link {
        color: #E9EBF2 !important;
        padding: 8px 16px 6px 40px !important;
        font-size: 15px;
        margin-bottom: 15px;
    }
  
    .nav-link:hover, .nav-non-link:hover{
        background: #68368B 0% 0% no-repeat padding-box;
        border-radius: 3px;
        opacity: 1;
    }
  
    .nav-link:focus, .nav-non-link:focus{
        background-color: #482659;
        border-radius: 2px;
        border: 1px solid #40244E;
        width: 90%;
        height: 48px;
    } 
  
    .navbar-items-header{
        box-shadow: 0px 8px 15px -8px #330d46;
        margin-bottom: 25px;      
        height: 50px;
        padding-left: 35px;
        display: inline-flex;
    }
  
    .navbar-items-header #solera-logo{
        margin-right: 12px;
        max-width: 30px;
        width: 12%;
    }
  
    .navbar-items-header #loc-intel-logo{
        float: right;
        padding-top: 2px;
        max-width: 171px;
        width: 55%;
    }
  
    .navbar-items-header .close-icon{
        color: #fff;
        flex-grow: 100;
        padding-right: 18px;
        text-align: end;
        align-self: center;
        justify-content: flex-end;
    }
  
    .nav-link svg{
        margin-right: 12px;
    }

    .navbar-toggler{
        display: inline-flex !important;
    }
    .navbar-expand-md{
        justify-content: space-between !important;
    }
    .collapse:not(.show) {
        display: none !important;
    }
}

/* Small screens - Mobile Devices only */
@media only screen and 
(max-width: 767px) and (orientation: portrait),
(max-width: 926px) and (orientation: landscape) {
    .login-nav, .main-nav, .home-nav{
        height: 50px !important;
    }

    .navbar-brand img{
        max-width: 309px;
    }
}