/* Customize the label (the container) */
.option-check-container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font: normal normal 300 14px 'Roboto-Regular';
    color:#292929;
    
  }
  
  /* Hide the browser's default checkbox */
  .option-check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .option-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 2px solid #292929;
  }
  
  /* When the checkbox is checked, add a blue background */
  .option-check-container input:checked ~ .option-checkmark {
    background-color: #292929;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .option-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .option-check-container input:checked ~ .option-checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .option-check-container .option-checkmark:after {
    left: 2px;
    top: -1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .day-row{
    font-family: 'Roboto-Bold';
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.14px;
    color: #000000DE;
    display: inline-flex;
    height: 50px;
    margin-bottom: 25px;
  }

  .time-picker-container{
    height: 40px;
    margin-top: 5px;
    font-size: 14px;
    position: relative;
    width: 140px;
  }

  .bh-error {
    font-family: Roboto-Regular;
    color: #F5222D;
    margin-top: 0;
  }

  .error > .react-time-picker__wrapper{
    border: solid 2px red;
  }

  .add-hours-disabled{
    color: #C8C8C8;
  }

  .add-hours-container{
    color: #000000DE;
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-family: 'Roboto-Medium';
    font-size: 16px;
}

.add-hours-container svg:nth-child(2){
    display: none; 
}
  
.add-hours-container:hover svg:nth-child(1){
    display: none;
}
  
.add-hours-container:hover svg:nth-child(2){
    display: inline-block;
}

.hidden-timepickers{
  display: none !important;
}