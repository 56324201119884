.mobile-map-label {
    font-family: Roboto-Bold;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #68368b;
    margin-bottom: 9px;
}

.mobile-location-details-map {
    width: 100%;
    height: 35vmax;
    /* background: transparent url('img/Component 316.png') 0% 0% no-repeat padding-box; */
    opacity: 1;
    background: #D1D1D1;
    position: sticky;
}

.mobile-no-geofence-data-div {
    background: white;
    height: 100%;
    position: relative;
}

.mobile-no-geofence-data-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    /* -ms-transform: translate(-50%, -50%); */
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    height: 100%;
}

.mobile-no-geofence-data-div p {
    font-family: Roboto-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #292929;
    padding-top: 30px;
}

.mobile-no-geofence-data-div img {
    width: 85%;
    height: 75%;
    margin-left: 25px;
}