.users-container::-webkit-scrollbar {
    width: 4px;
}
  
/* Track */
.users-container::-webkit-scrollbar-track {
    background: #fff; 
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Handle */
.users-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 7px;
}

/* Handle on hover */
.users-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.users-container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);
    width: 100%;
    max-height: 45vh;
    padding: 7% 5% 7% 5%;
    overflow-y: auto;
    overflow-x: hidden;
    direction:rtl;  
}

.user-initials{
    display: flex;
    min-width: 37px;
    width: 37px;
    height: 37px;
    cursor: pointer;
    background-color: #215BA6;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.user-item-container{
    width: 100%;
    margin-bottom: 10%;
    margin-left: 2%;
    vertical-align: middle;
    word-spacing: 1px;
    direction:ltr;
    position: relative;
    display: inline-flex;
}

.user-item{
    display: contents;
}

.user{
    margin-left: 5%;
    line-height: 1rem;
    font-weight: 500;
}

.user-fullname{
    align-items: center;
    margin-top: 0.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 190px;
    display: block;
    font-size: 16px;
    font-weight: 500;
}   

.user-title{
    color:#215BA6;
    font-size: 14px;
}

.user-info{
    width: 280px;
    padding: 7%;
    vertical-align: middle;
    border: 1px solid #0B2F7B;
    border-radius: 7px;
    color: black;
}

.user-info-close{
    cursor: pointer;
    position: absolute;
    top: 2%;
    right: 5%;
}

.user-info-table{
    margin-top: 5%;
    margin-bottom: 0;
    border-top: 1px solid #70707080;
}

.user-info td{
    padding: 0 0 7% 0;
    border: none;
}

.user-info .user-title{
    color: #525252;
    font-weight: 500;
    margin: 0;
    line-height: 1rem;
}

.user-contact-label{
    font-size: 13px;
    font-weight: 600;
    margin-top: 5%;
    margin-bottom: 0;
}

.user-contact-content{
    font-size: 14px;
    margin: 0;
}

.user-info img{
    margin-right: 3%;
}

.user-email{
    color: #215BA6;
}

.user-info-label{
    font-size: 11px;
    font-weight: 750;
    margin-top: 5%;
    margin-bottom: 0;
} 

.user-info-content{
    font-size: 13px;
    margin: 0;
}