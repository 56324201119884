.HomeLayout {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    max-width: 100%;
    padding: 0!important;
}

.HomeLayout .navbar-items-container .btn-submit{
    padding: 1.5vh 3.4vw !important;
    border-radius: 3px;
    font-size: 1.1rem;
    color: #FFFFFF !important;
    min-width: fit-content;
}

.HomeLayout .btn-signup, .HomeLayout .btn-signup:hover, .HomeLayout .btn-signup:active, .HomeLayout .btn-signup:focus{
    background-color: #32255E;
}

.Home-Layout-Body {
    margin-bottom: 5%;
}