.location-details-side-bar{
    width: 300px;
    height: 100%;
    opacity: 1;
    background-color: white;    
}

.location-details-right-container{
    width: calc(100% - 300px);   
}

.details-container {
    border-bottom: solid 0.5px rgba(82, 82, 82, 0.25);
    padding: 15px 25px;
}

.location-details-map{
    height: 470px;
    opacity: 1;
    background: #D1D1D1;
    margin-bottom: 30px;
}

.location-details-name{
    opacity: 1;
    font-family: Roboto-Light;
    font-size: 32px;
    margin-top: 25px;
    margin-bottom: 18px;
}

.location-details-main{
    display: flex;
    width: 100%;
}

.word-cloud-label, .analytics-label{
    background-color: #0B2F7B;
    color: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
    font-family: Roboto-Medium;
    font-size: 16px;
    padding-left: 15px;
    height: 50px;
    display: flex;
    align-items: center;
}

.analytics-label-internal{
    background-color: red !important;
}

.word-cloud-child{
    display: inline-block;
    border:2px solid #D1D1D1;
    border-radius: 6px;
    margin-right: 10px;
    margin-bottom: 8px;
    padding: 1%;
}

.word-cloud-main{
    justify-content: space-between;
}

.word-cloud-section{
    background-color: #FFFFFF;
    padding: 15px;
}

.word{
    font-family: Roboto-Regular;
    font-size: 17px;
    color: #6E6D6A;
}

.count{
    font-family: Roboto-Medium;
    font-size: 17px;
}

.location-details-desktop-tabs {
    font-family: Roboto-Medium;
}

.no-geofence-data-div {
    background: white;
    height: 470px;
    position: relative;
  }

.no-geofence-data-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    /* -ms-transform: translate(-50%, -50%); */
    transform: translate(-50%, -50%);
    text-align: center;
  }

.additional-names-link {
    text-decoration: underline;
}

.additional-names{
    pointer-events: auto !important;
}

.additional-name{
    font-family: Roboto-Medium;
    font-size: 13px;
}

.additional-names-div{
    max-height: 400px;
    overflow-y: auto;
}

.analytics-section{
    background: white;
}

.location-details-word-wrap{
    white-space: pre-wrap; /* CSS3 */    
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */    
    white-space: -o-pre-wrap; /* Opera 7 */    
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-word;
}
