.modal-container {
    width: 680px;
    max-width: 680px;
    height: 680px;
}

.integration-user-modal-container{
    width: 50%;
    max-width: 50%;
    height: 90%;
    max-height: 90%;
}

.sign-up-modal-container{
    min-width: 35%;
}

.sign-up-modal-container .modal-content{
    border-radius: 0px;
}

.sign-up-modal-container button {
    border-radius: 4px;
}

.modal-header{
    background: #0B2F7B 0% 0% no-repeat padding-box;
    text-align: left;
    font: normal normal bold 24px/24px Roboto;
    letter-spacing: 0.17px;
    color: #FFFFFF;
    opacity: 1;
    padding: 0.2rem 1rem;
}

.sign-up-modal-container .modal-header{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    text-align: left;
    opacity: 1;
    padding: 20px 45px;
    border-bottom: none;
    color: #000000;
}

.modal-title{
    display: inline-flex;
    float: left;
    width: 100%;
    font-size: 1.2rem;
}

.sign-up-modal-container .modal-title{
    display: inline-grid;
    font-size: 22px;
}

.sign-up-modal-container .modal-title span{
    font: normal normal medium 22px/29px Roboto;
    text-align: center;
}

.sign-up-modal-container .modal-title p{
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #515151;
    text-align: center;
}

.sign-up-modal-container .modal-title img{
    height: 20px;
}

.modal-description{
    font-weight: normal;
    line-height: 1.5rem;
    padding-bottom: 2%;
}

.modal-description div{
    font-size: 16px;
    margin-bottom: 3%;
}

.modal-body{
    text-align: left;
    font: normal normal bold 14px/28px Roboto;
    letter-spacing: 0.07px;
    color: #000000;
    opacity: 1;
    padding: 25px 55px 10px 55px;
}

.modal-form-label{
    text-transform: uppercase;
    font-size: 16px;
    padding-top: 2%;
}

.modal-body form {
    width: 100%;
    padding-top: 20px;
}

.modal-body .row{
    margin-bottom: 20px;
    height: 40px;
}

.medium-form-group{
    width: 50%;
    height: 40px;
    padding-right: 15px;    
    margin-bottom: 20px;
}     

.medium-form-group.inline-right,
.large-form-group.inline-right {
    padding-right: 0;
    padding-left: 15px;
}

.inline-left {
    padding-right: 10px;
}

.inline-right {
    padding-left: 10px;
}

.large-form-group{
    width: 100%;
    height: 40px;  
    margin-bottom: 20px;
}

.modal-footer {
    border: none;
    padding: 15px 30px 30px 30px;
}

.sign-up-modal-container .modal-footer {
    border: none;
    padding: 15px 55px 30px 55px;
}

.btn-submit{
    padding: 8px 24px;
    margin-left: 20px;
    background: #215BA6 0% 0% no-repeat padding-box;
    border-color: #215BA6;
    border-radius: 21px;
    opacity: 1;
    text-align: center;
    font: normal normal bold 14px/18px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.btn-submit:hover,
.btn-submit:active,
.btn-submit:focus,
.btn-square:hover,
.btn-square:active,
.btn-square:focus{
    background: #0B2F7B 0% 0% no-repeat padding-box;
    border-color: #0B2F7B;
    box-shadow: none;
}

.btn-cancel{
    padding: 8px 24px;
    background-color: #FFFFFF;
    border: 1px solid #215BA6;
    border-radius: 21px;
    opacity: 1;
    text-align: center;
    font: normal normal bold 14px/18px Roboto;
    letter-spacing: 0px;
    color: #215BA6;
    opacity: 1;
}

.btn-cancel:hover,
.btn-cancel:active,
.btn-cancel:focus{
    background: #FFFFFF;
    border-color: #0B2F7B;
    color: #0B2F7B;
    box-shadow: none;
}

.btn-square {
    padding: 6px 24px;
    background: #215BA6 0% 0% no-repeat padding-box;
    border-color: #215BA6;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal bold 14px/18px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.radio-button-toggle-wrapper {
    clear: both;
    display: flex;
    font: normal normal normal 14px/16px Roboto;
    padding: 8px 0 12px 0;
  }
  
  .radio-button-toggle {
    position: absolute;
    left: -9999em;
    top: -9999em;
  }
    
    .radio-button-toggle + label {
      float: left;
      padding: .5em 1em;
      cursor: pointer;
      border: 1px solid #707070;
      margin-right: -1px;
      color: #292929;
      background-color: #FFFFFF;     
      
    }
    .radio-button-toggle + label:first-of-type {
        border-radius: 16px 0 0 16px;
      }
      
      .radio-button-toggle + label:last-of-type {
        border-radius: 0 16px 16px 0;
      }  

    
    .radio-button-toggle:checked + label {
      background-color: #215BA6;
      border: 1px solid #215BA6;
      color: #FFFFFF;
    }

/*----FLOATING LABEL----*/
.async-floating-label{
    position: relative;
}

.async-floating-label .rbt-loader{
    display: none;
}

    .async-floating-label input {
        font-size: 14px;
        font-family: Roboto-Regular;
        padding: 10px 16px;
        min-height: 40px;
        border: solid 2px #C8C8C8;
        border-radius: 4px;
        background: transparent 0% 0% no-repeat padding-box;
        width: 100%;
        box-sizing: border-box;
        transition: all .3s linear;
        color: #2e2e2e;
        font-weight: 400;
        margin-bottom: 20px;
    }

        .async-floating-label input:focus {
            outline: 0;
            box-shadow: none;
        }

        .async-floating-label input:not(:placeholder-shown) {
            background: #ffffff;
        }

        .async-floating-label input:-internal-autofill-selected,
        .async-floating-label input:-webkit-autofill {
            background: #ffffff;
        }

        input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px #ffffff inset;
 box-shadow: 0 0 0 30px #ffffff inset;
}

        .floating {
            opacity: 1;
            background-color: #ffffff;
            font-size: 10px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: #9e9e9e;
            padding: 0 2px;
        }

    .hidden-label {
        font-size: 12.5px;
        color: #000;
        opacity: 0;
        font-weight: 400;
        position: absolute;
        top: calc(50% - 7px);
        transition: all .3s ease;
        width: fit-content;
    }

    .small-field {
        margin-bottom: -30px;
    }

.floating {
    transform: translate(15px, -74px);
}

.async-floating-label .error input,
.async-floating-label .error input:-internal-autofill-selected,
.async-floating-label .error input:-webkit-autofill {
    border-color: #F5222D !important;
    -webkit-box-shadow: 0 0 0 30px #FFEAEA inset;
 box-shadow: 0 0 0 30px #FFEAEA inset;
}

.error.form-text {
    font-size: 12px;
    font-family: Roboto-Regular;
    color: #F5222D;
    padding-left: 16px;
    margin-top: 0;
    margin-bottom: -17px;
    height: 17px;
    display: contents;
    text-align: justify;
}

.hide-box{
    display: none;
}

.modal-close{
    cursor: pointer;
    position: absolute;
    top: 0.5%;
    right: 2%;
}

.modal-confirmation{
    top: -10%;
    width: 580px;
}

.modal-img{
    margin-right: 2%;
}

.modal img{
    margin-bottom: 0.1rem;
}

.btn-primary:disabled {
    color: #C8C8C8;
    background-color: #E2E2E2;
    border: none;
}

.sign-up-modal-container .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #3353AE99;
    border: none;
}

.modal-container .spinner-container{
    width: 80%;
}

.modal-container .spinner svg{
    width: 70px;
    height: 70px;
}

.modal-container .spinner span, .modal-container .spinner p{
    visibility: hidden;
}

.sign-up-spinner-container {
    position: absolute;
    width: 100%;
    padding: 55px;
    margin-left: -55px;
    height: 95%;
    backdrop-filter: blur(5px);
}

.sign-up-spinner-container .spinner {
    margin: auto;
}

/* CSS for Mobile and Tablet*/
@media only screen and 
(max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape){

    .forgot-password-body .medium-form-group{
        width: 90%;
    }

    .btn-cancel{
        border: 1px solid #68368B;
        color: #68368B;
        width: 100%;
        padding: 10px 24px;
        margin-bottom: 16px;
    }

    .btn-submit{
        background: #68368B 0% 0% no-repeat padding-box;
        border-color: #92918F;
        color: #FFFFFF;
        width: 100%;
        margin-left: 0;
        margin-bottom: 16px;
        padding: 10px 24px;
    }

    .btn-submit:disabled{      
        color: #6E6D6A;
        border: 1px solid #525252;
        background-color: #525252;
    }

    .btn-cancel:disabled{
        color: #6E6D6A;
        border: 1px solid #525252;
        background-color: #fff;
    }

    .btn-submit:hover, .btn-submit:active, .btn-submit:focus {
        background: #482659 0% 0% no-repeat padding-box !important;
        border-color: #92918F !important;
    }

    .btn-cancel:active, .btn-cancel:focus, .btn-cancel:hover{
        border-color: #57078E !important;
        color: #57078E !important;
    }

    .btn-back-mobile{
        width: 100%;
        padding: 15px 24px 5px 24px;
        color: rgb(104, 54, 139);
        font-family: Roboto-Bold;
    }

    .btn-back-mobile svg{
        margin-right: 6px;
        font-size: 17px;
    }

    .btn-back-mobile span{
        font-size: 14px;
    }

    .modal-container{
        width: unset;
        height: unset;
    }

    .modal-header{
        background-color: #68368B;
    }

    .modal-header svg{
        font-size: 1.2rem;
    }

    .modal-title{
        font-size: 16px;
    }

    .modal-description div{
        font-size: 15px !important;
    }

    .modal-body, .sign-up-modal-container .modal-header, .sign-up-modal-container .modal-footer{
        padding: 15px 30px 0 25px;
    }

    .sign-up-modal-container .modal-header .close{
        padding: 1rem 0;
    }

    .sign-up-modal-container .modal-title img{
        height: 15px;
    }

    .sign-up-modal-container .modal-body{
        padding-top: 0;
    }

    .sign-up-modal-container .form-group{
        margin-bottom: 0;
    }

    .modal-footer{
        padding: 20px;  
    }

    .modal-footer button{
        padding: 8px 24px;
        margin-left: 7px;
        width: unset;
    }

    .floating-alert-new-user{
        top: 20px;
    }
}

/* CSS for Mobile - landscape view and Tablet */
@media only screen and 
(min-width: 429px) and (max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape) {

    .btn-container-mobile{
        bottom: unset !important;
        width: 50% !important;
        display: flex;      
    }

    .btn-container-mobile .btn-submit, .btn-container-mobile .btn-cancel{
        padding: 5px 24px;
        margin-right: 3%;
    }

    .modal-container{
        max-width: 440px;
        margin: auto;
        top: 5px;
    }

    .modal-confirmation{
        top: unset;
    }

    .floating-alert{
        top: 5px !important;
    }
}