.main-closure-toggle{
    padding: 2px;
}

.main-closure-toggle-label{
    font-family: Roboto;
    font-size: 16px;
}

.main-closure-toggle-label-bold{
    font-family: Roboto-Bold;
    font-size: 16px;
}