.login-nav, .main-nav, .home-nav {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  max-width: 100%;
  padding: 0 0 0 35px;
  margin: 0 !important;
  font-family: Roboto-Regular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  border-bottom: solid 2px #68368B;
  border-radius: 1px;
}

.navbar-brand {
  max-width: fit-content;
}

.navbar-items-container {
  padding-left: 70px;
}

.nav-link:hover{    
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

.nav-link.active {
  border-bottom: solid 2px #68368B;
  border-radius: 1px;
}

.navbar-items {
  bottom: 0;
}

.HomeLayout .navbar-items-container{
  justify-content: right;
}

.HomeLayout .home-nav{
    padding: 0 12%;
}

@media only screen and 
(min-width: 1024px) and (orientation: portrait),
(min-width: 1280px){
  .login-nav, .main-nav, .home-nav {
    height: 90px;
  }

  .nav-link {
    padding: 8px 16px 6px !important;
    color: #000000 !important;
  }
}