.radio-label{
    font-family: Roboto-medium;
    font-size: 14px;
    line-height: 1.5rem;
}

.radio-container{
    padding-top: 1%;
}

.radio-container p{
    margin: 0;
}

.radio-container [type="radio"]:checked,
.radio-container [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.radio-container [type="radio"]:checked + label,
.radio-container [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 4%;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000000DE;
    font-size: 16px;
}
.radio-container [type="radio"]:checked + label:before,
.radio-container [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #292929;
    border-radius: 100%;
    background: #fff;
}
.radio-container [type="radio"]:checked + label:after,
.radio-container [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #292929;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.radio-container [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.radio-container [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.MuiToggleButtonGroup-root{
    margin-top: 15px;
    margin-bottom: 10px;
    border: 1px solid #C8C8C8 !important;
    border-radius: 20px !important;
}
.css-ueukts-MuiButtonBase-root-MuiToggleButton-root{
    border: none !important;
}
.MuiToggleButtonGroup-root button{
    padding: 0;
    border: none !important;
}
.MuiToggleButtonGroup-root span{
    font-size: 14px;
    text-transform: capitalize;
    padding: 2px 15px;
}
.MuiToggleButtonGroup-grouped:first-of-type span:first-of-type{
    border-right: 1px solid #C8C8C8;
    line-height: 10px;    
}

.MuiToggleButtonGroup-grouped:last-of-type span:first-of-type{
    border-left: 1px solid #C8C8C8;
    line-height: 10px;    
}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected, .css-q5cu77.Mui-selected{
    color: #fff !important;
    background-color: #68368B !important;
    border-radius: 20px !important;
}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected span:first-of-type, .css-q5cu77.Mui-selected span:first-of-type{
    border: none !important;
}