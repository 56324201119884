.mobile-analytics-container {
    margin: 10px 24px 15px 24px;
    padding: 10px 20px 12px 12px;
    object-fit: contain;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px rgba(82, 82, 82, 0.25);
    text-align: center
}

.mobile-analytics-title {
    font-family: Roboto-Regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    color: #292929;
    margin-top: 10px;
    margin-bottom: 5px;
}

.mobile-analytics-data {
    font-family: Roboto-Bold;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #292929;
    margin: 0;
}

.mobile-analytics-data-units, .no-data {
    font-family: Roboto-Bold;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    color: #292929;
}

.no-analytics{
    text-align: center;
    font-family: Roboto-Bold;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #292929;
}