.location-search-mobile{
    background-color: #FFFFFF;
    height: 98%;
}

.location-search-body-mobile{
    margin: 10px 24px 20px 24px;
    width: auto;
    height: 96%;
    min-height: 470px;
    position: relative;
}

.location-search-title-mobile{
    width: 100%;
    color: #68368B;
    text-align: left;
    text-transform: uppercase;
    font-size: 18px;
    font-family: 'Roboto-Bold';
    margin-bottom: 17px;
}

.num-of-results-text{
    text-transform: lowercase;
    font-family: 'Roboto-Regular';
    font-size: 12px;
    margin-left: 12px;
}

.results-header-mobile{
    padding: 0px 24px;
    height: 40px;
    background-color: #683688;
    color: #fff;
    margin: 0;
    font-size: 18px;
    align-items: center;
    display: inline-flex;
}

.location-search-mobile .search-type-toggle-wrapper{
    border: 1px solid #C8C8C8;
    float: left;
    margin: 0;
}

.location-search-mobile .search-type-toggle + label{
    padding: 0px 16px;
    margin-bottom: 0;
    align-items: center;
    display: flex;
}


.location-search-mobile .search-type-toggle:checked + label{
    font-family: 'Roboto-Bold';
    background-color: #68368B;
    outline: 2px solid #FFFFFF;
    border: none;
    box-shadow: #0077c348 0px 0px 8px 3px;
}

.location-search-mobile .navbar{
    padding: 0;
    float: right;
}

.location-search-mobile .navbar-toggler {
    display: block !important;
    padding: 0;
}

.location-search-mobile .navbar-items-header{
    height: auto;
    margin-top: 50px;
}

.location-search-mobile .navbar-items-container{
    top: 130px;
    margin-left: 0;
    left: 0;
    height: calc(100% - 130px);
    z-index: 1;
}

.location-search-mobile .navbar-items{
    width: 65%;
    min-width: 267px;
}

.location-types-filter-header{
    font-size: 18px;
    font-family: 'Roboto-Medium';
    color: #FFFFFF;
    text-transform: uppercase;
}

.location-types-filter-body{
    height: calc(100% - 110px);
    overflow-y: auto;
}

.location-search-mobile .filter-check-container{
    color: #E9EBF2;
    margin-bottom: 0;
    font-size: 15px;
    padding-left: 38px;
    font-family: 'Roboto-Medium';
}

.location-search-mobile .filter-checkmark{
    border-color: #E9EBF2;
    background-color: #68368B;
}

.location-search-mobile .filter-check-container input:checked ~ .filter-checkmark{
    background-color: #E9EBF2;
}

.location-search-mobile .filter-checkmark:after {
    border-color: #68368B;
}

.location-search-mobile .location-search-body-margin{
    margin-top: 12.5%;
}

.location-search-inputbox-container{
    margin-top: 12.5%;
}

.location-search-mobile .search-textbox, .location-search-mobile .search-dropdown{
    width: 100% !important;
    height: 40px;
}

.location-search-mobile .input-container{
    margin-bottom: 34px;
}

.location-search-mobile .floating-label{
    width: 100%;
}

.location-search-mobile select{
    max-height: 50px;
}

.location-search-mobile .btn-container-mobile{
    bottom: 0;
    position: absolute;
    width: 100%;
}

/* CSS for landscape view */
@media only screen and 
(min-width: 429px) {

    .location-search-body-mobile{
        height: calc(100% - 90px);
        min-height: 190px;
    }
    
    .location-search-inputbox-container{
        margin-top: 5vmin;
        display: inline-flex;
        width: 100%;
    }

    .location-search-mobile .input-container{
        width: 100%;
        margin-bottom: 5vmin;
    }

    .location-search-mobile .input-container:not(:last-child){
        padding-right: 3%;
    }

    .location-search-mobile .navbar-items-container{
        top: 110px !important;
        height: calc(100% - 110px);
    }

    .location-search-mobile .navbar-items{
        width: 83% !important;
    }

    .location-types-filter-body{
        max-height: 70px;
        min-height: 55px;
        display: inline-flex;
        flex-wrap: wrap;
        margin-top: -5px;
        padding-left: 40px;
        padding-right: 30px;
    }
    
    .location-search-mobile .nav-link{
        width: 33%;
        margin-bottom: 1vmin;
        padding: 8px 4px 8px 0px !important;
    }

    .location-search-mobile .filter-check-container {
        padding-left: 3.5vw;
    }
}

@media only screen and 
(max-height: 320px) and
(orientation: landscape){

    .location-search-mobile .navbar-expand-md .navbar-collapse {
        display: unset;
    }
    
    .location-search-mobile .navbar-items-container{
        overflow-y: auto;
        height: calc(100% - 110px);
    }

    .location-search-mobile .navbar-items{
        height: 220px;
    }

    .location-types-filter-body{
        height: 70px;
        max-height: unset;
        overflow-y: hidden;
    }

    
}

@media only screen and 
(max-width: 638px) and 
(orientation: landscape){

    .location-search-mobile .navbar-items-container{
        overflow-y: auto;
    }

    .location-search-mobile .nav-link{
        width: 50% !important;
    }

    .location-search-mobile .navbar-items{
        height: 260px;
    }

    .location-types-filter-body{
        height: 120px;
        max-height: unset;
        overflow-y: hidden;
    }
}

@media only screen and 
(min-width: 429px) and (max-width: 1023px) and (orientation: portrait) {
    .location-search-mobile .navbar-items-container{
        top: 220px !important;
        height: calc(100% - 220px);
    }
}

@media only screen and 
(min-width: 1024px) and (max-width: 1279px) and (orientation: landscape) {
    .location-search-mobile .navbar-items-container{
        top: 150px !important;
        height: calc(100% - 150px);
    }
}