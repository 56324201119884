.Search-Container{
    position: relative;
    background-image: url(../../images/Location-Search-Image.png);
    background-size: cover;
    width: 100%;
    height: 100%;
}

@media only screen and
(min-height: 1024px) and (orientation: portrait) {

  .Search-Container{
    background-image: url(../../images/Location-Search-Image-Portrait.png);
}
  
}

.Search-Image img{
  width: 100%;
  height: 800px;
}

.Search {
    position: absolute;
    padding: 30px 0 0 4.7vmax;
    z-index: 1;
}

.Search h3 {
    text-align: left;
    font: normal normal 300 32px/38px Roboto;
    letter-spacing: -0.64px;
    color: #FFFFFF;
    opacity: 1;
    padding-bottom: 35px;
}

.search-header{
  background: #FFFFFF;
  border-radius: 8px;
  border: 2px solid #007EB5;
  padding: 15px;
}

.filter-toggle{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font: normal normal 300 14px/18px 'Roboto-Regular';
  margin-left: 30px;
}

button.filter-toggle:active,
button.filter-toggle:focus,
button.filter-toggle:hover{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.filter-menu {
  width: 400px;  
  padding: 20px 20px 0 20px;
}

.filter-menu .form-group{
  display: inline-flex !important;
  margin: 0 !important;
}

.filter-options{
  font: normal normal 300 14px 'Roboto-Regular';
  color:#292929;
  padding: 0;
}

.filter-options.left {
  padding-right: 70px;
}

.Search-Container .search-textbox, .Search-Container .search-dropdown{
  font: normal normal normal 14px/20px 'Roboto-Regular' !important;
  background: transparent;
  height: 32px;
  border: 1px solid #C8C8C8;
  border-radius: 4px;
  margin-right: 30px;
  width: 210px !important;
}

.Search-Container .search-textbox .form-control, .Search-Container .search-dropdown .form-control {
  border: none;
  box-shadow: none;
  color: #000000DE;
}

.Search-Container .search-textbox:focus-within, .Search-Container .search-dropdown:focus-within{
  border: 2px solid #007EB5;
  border-radius: 4px;
  box-shadow: none;
}

.Search-Container .input-container{
  margin: auto;
}

.Search-Container .search-textbox span, .search-dropdown span {
    background: transparent;
    border: none;
}

.Search-Container .floating-label input, .floating-label select {
  margin-bottom: 0 !important;
}

.location-search-btn{
  background-color: transparent;
  font: normal normal bold 14px/18px 'Roboto-Regular';
  border-radius: 20px;
  border: 2px solid #215BA6;
  background-color: #215BA6;
  color: #FFFFFF;
    text-transform: capitalize;
    width: 60px;
    height: 40px;
}

.location-search-btn:disabled {
    color:#c8c8c8;
    border-color: #c8c8c8;
    box-shadow: none;
    background-color: transparent;
}

.location-search-btn:hover,
.location-search-btn:focus{    
    border-color: #0B2F7B;
    box-shadow: none;
    background-color: #0b2f7b;
}

.clear-button{
  margin-left: 10px;
  height: 36px;
  background-color: transparent;
  font: normal normal bold 14px/18px 'Roboto-Regular';
  border:none;
  color: #215BA6;
    text-transform: capitalize;
    padding: 5px 15px;
}

.clear-button:hover,
.clear-button:focus{
    color: #0B2F7B;
    border: none;
    box-shadow: none;
    background-color: transparent;
    text-decoration: none;
}

.clear-button:disabled{
  color: #C8C8C8;
}

.search-header-top{
  display: flex;
}

.floating-label input, .floating-label select {
    margin-bottom: 0 !important;
}