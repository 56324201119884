.company-locations-body::-webkit-scrollbar {
    width: 4px;
    margin-top: 4px;
  }

  /* Track */
  .company-locations-body::-webkit-scrollbar-track {
    background: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
  }
   
  /* Handle */
  .company-locations-body::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 7px;
  }
  
  /* Handle on hover */
  .company-locations-body::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.company-locations-header{
    background-color: #0B2F7B;
    color: #FFFFFF;
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    font-family: Roboto-Medium;
    font-size: 16px;
    padding: 0.5% 0% 0.5% 3%;
    word-spacing: 1px;
}

.company-locations-header p{
    margin-bottom: 0;
}

.company-locations-body{
    overflow-y: auto;
    height: fill-available;
}

.company-locations-body table{
    margin-bottom: 0;
}

.company-locations-body thead th{
    position: sticky; 
    top: 0; 
    z-index: 1;
}