.icon-container{
    float: right;
    color: #215BA6;
    z-index: 2;
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 6px;
}

.icon-container svg:nth-child(2){
    display: none; 
}
  
.icon-container:hover svg:nth-child(1){
    display: none;
}
  
.icon-container:hover svg:nth-child(2){
    display: block;
}

.dynamic-form-text{
    font-size: 12px;
    font-family: Roboto-Regular;
    color: #92918F;
    padding-left: 16px;
    margin-top: 0;
}

.dynamic-form-container .error{
    color: #F5222D !important;
}

.dynamic-form-container{
    padding-top: 2%;
    width: 100%;
}

.dynamic-form-container .floating-label-inputbox{
    width: 30vmin;
    height: 40px;
    margin-bottom: 30px;
    position: relative;
}

.dynamic-form-container .async-floating-label{
    display: block; 
    margin-bottom: 30px; 
    width: 30vmin;
}

.added-textbox-bottom-margin{
    margin-bottom: 5px !important;
}

.dynamic-form-container input{
    padding-right: 13%;
}

.dynamic-form-container input:focus{
    border-color: #215BA6;
}

@media only screen and
(max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape){
    .dynamic-form-container input:focus{
        border-color: #68368B;
    }
    .icon-container{
        color: #68368B;
    }
    .dynamic-form-container .async-floating-label{
        width: 100%;
        margin-top:  8%;
    }
    .dynamic-form-container .floating-label-inputbox{
        width: 100%;
    }
}

@media only screen and
(min-width: 768px) and (max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape){
    .dynamic-form-container .async-floating-label, .dynamic-form-container .floating-label-inputbox{
        width: 50%;
        min-width: 270px;
    }
}