@media only screen and
(max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape){

    .edit-container{
        margin: 5px 24px;
        width: unset;
    }

    .edit-section-box{
        width: unset;
        max-width: unset;
        box-shadow: #00000026 0px 0px 7px -1px;
        font-size: 16px;
    }

    .edit-section-box svg {
        color: #68368B;
    }

    .edit-section-box.active {
        color: #292929;
        border-top: 1px solid #68368B;
        border-bottom: 1px solid #68368B;
        border-left: 8px solid #68368B;
        border-right: 1px solid #68368B;
        padding-left: 32px;
    }

    .edit-details-container .floating-label input:focus{
        border-color: #68368B;
    }

    .edit-details-header{
        display: none;
    }

    .hide-on-mobile-view{
        display: none;
    }

    .edit-details-container{
        background-color: unset;
        box-shadow: none;
        width: 100%; 
    }

    .edit-details-body{
        padding: 0;
    }

    .services-container .edit-details-body-padding, .safety-requirements .edit-details-body-padding{
        padding: 10px;
        background-color: #fff;
        border: 1px solid #52525240;
        border-radius: 4px;
        margin-bottom: 10px;
        box-shadow: #00000026 0px 0px 5px 1px;
    }

    .radio-container{
        padding: 10px;
        background-color: #fff;
        border: 1px solid #52525240;
        border-radius: 4px;
        margin-bottom: 10px;
        box-shadow: #00000026 0px 0px 5px 1px;
    }

    .appointment-checkbox-container {
        margin-top: 5%;
    }

    .checkbox-margin-bottom{
        margin-bottom: 8%;
    }

    .option-check-container{
        font-size: 15px !important;
        padding-left: 36px !important;
        font-family: 'Roboto-Medium';
    }

    .option-checkmark{
        border-color: #68368B;
    }

    .option-check-container input:checked ~ .option-checkmark {
        background-color: #68368B;
    }

    .services-container .radio-label, .safety-requirements .radio-label, .procedures .radio-label{
        font-size: 14px;      
    }

    .services-container .label-description, .safety-requirements .label-description, .procedures .label-description{
        color: #525252DE;
    }

    .services-container .edit-details-body-padding{
        min-height: 140px;
    }

    .edit-details-title {
        font-size: 14px;
    }

    .textarea-padding-mobile{
        padding: 15px 0 10px 2px;
    }

    .text-area-container {
        width: 100%;
        margin-top: 8%;
        padding-right: 0;
    }

    @media only screen and
    (max-width: 359px) and (orientation: portrait),
    (min-width: 768px) and (max-width: 1023px) and (orientation: portrait),
    (max-width: 1279px) and (orientation: landscape) {

        .edit-container-body{
            padding-top: 0;
        }

        .edit-details-container{
            padding-bottom: 0;
        }

        .detail-arrow{
            margin: 10px 14px;
        }

        .textarea-padding-mobile{
            padding: 0 2px;
        }
    
        .text-area-container, .appointment-checkbox-container {
            margin-top: 2%;
        }

        .dynamic-form-container .async-floating-label, .checkbox-margin-bottom {
            margin-top: 0;
            margin-bottom: 0;
        }
        
    }

    @media only screen and
    (min-width: 768px) and (max-width: 1023px) and (orientation: portrait),
    (max-width: 1279px) and (orientation: landscape) {
        .edit-details-container{
            padding-bottom: 2%;
        }
        .radio-container{
            height: 75px;
        }
        .radio-container .label-description{
            float: left;
        }
        .radio-container .MuiToggleButtonGroup-root{
            float: right;
            margin-top: -5px;
        }
        .MuiToggleButtonGroup-root span{
            padding: 0 1.5vw;
        }
        .appointment-checkbox-container{
            display: flex;
            width: 100%;
            flex-flow: row wrap;
            margin-bottom: 0;
        }
        .checkbox-margin-bottom {
            width: 33.3%;
            margin-bottom: 2vh;
        }
        .services-container .checkbox-margin-bottom, .services-container .appointment-checkbox-container, .services-container .floating-label {
            width: 45%;
        }

        .currency-type-container .floating-label{
            width: 93%;
        }
        
    }

    @media only screen and
    (max-width: 359px) and (orientation: portrait),
    (max-width: 926px) and (orientation: landscape){
        .edit-section-box{
            height: 45px;
            font-size: 14px;
            line-height: 45px;
        }
    }   
}