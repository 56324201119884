.dashboard-container{
    width: 100%;
    display: inline-block;
  }

.visible-monthly{
    display: block;
}

.hide-monthly{
    display: none;
}

.visible-visit-trends{
    display: block;
}

.hide-visit-trends{
    display: none;
}

.tabs-container{
    display: flex;   
    box-sizing: border-box;
    white-space: nowrap;
    position: relative; 
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

.tab-button{
    align-items: center;
    opacity: 1;
    cursor: pointer;
    color:inherit;
    padding: 6px 12px;
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;
    max-width: 264px;
    min-width: 160px;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    border: 0;
    margin: 0;
    display: inline-flex;
    outline: 0;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-font-smoothing: auto;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    padding: 6px 12px;
}

.tab-scroll{
    background-attachment: scroll;
    background-clip: border-box;
    background-origin: padding-box;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    bottom: 0%;
    width: 160px;
    background: rgb(0, 161, 49);
    bottom: 0;
    height: 2px;
    position: absolute;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    white-space: nowrap;
    position: absolute;
}

.dashboard.visit_trends{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 880px;
    width: 100%;
}

.dashboard.last_month{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 1250px;
    width: 100%;
}

.dashboard iframe{
    border: none;
    width: inherit;
    height: inherit;
}

@media screen  and (max-width: 1088px) {

    .dashboard.visit_trends{
        height: 1200px;
    }

    .dashboard.last_month{
        height: 1800px;
    }
}