.edit-location-container{
    background-color: white;
    padding: 1% 6%;
    display: inline-block;
    width: 100%;
}

.edit-location-button-container{
    position: relative;
    float: right;
}