@keyframes loading-spin {
    from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
}
.spinner{
    width: fit-content;
    position: relative;
}
.spinner svg{
    animation: loading-spin infinite 2s linear;
    animation-timing-function: ease-in-out;
}

.spinner p{
    font-size: 20px;
    font-family: Roboto-Regular;
    left: 80px;
    top: 120px;
    position: absolute;
}

.spinner-black p {
    color: #000;
  }

.spinner-black span span {
    background-color: #000;
}

.spinner-black {
    z-index: 1;
}

@media only screen and 
(max-width: 767px) and (orientation: portrait),
(max-width: 926px) and (orientation: landscape){

    .spinner-container {
        position: absolute;
        top: 45%;
        width: 100%;
    }

    .spinner svg{
        width: 125px;
        height: 125px;
    }
    
    .spinner p{
        font-size: 16px;
        left: 20px;
        top: 50px;
        color: #000;
    }
    
    .spinner .css-1fo0v8a {
        top: 50px;
        left: 80px;
    }
    
    .spinner .css-1n6c5h8, .spinner .css-jj4jh6, .spinner .css-1ftgtva {
        background-color: #000;
    }
}