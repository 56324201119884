.search-body, .no-results {
    position: absolute;
    top: 0;
    background-color: white;
    margin-top: 140px;
    width: 100%;
    padding: 4.5vh 45px 20px 5.2vmax;
    height: fill-available;
}

.search-results-container{
    padding-right: 20px;
    padding-top: 15px;
    width: 100%;
    height: 100%;
    margin-right: 1%;
    float: left;
}

.search-results-header{
    font-family: 'Roboto-Medium';
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: #292929;
    padding-bottom: 28px;
}

.map {
    width: 45%;
    height: 600px;
    float: right;
    border: 1px solid #0B2F7B5C;
    border-radius: 8px;
    display: none;
}

.map div{
    border-radius: 8px;
    height: 100%;
}

.search-results::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.search-results::-webkit-scrollbar-track {
    background: #fff; 
    margin-top: 10px;
    margin-bottom: 10px;
}
 
/* Handle */
.search-results::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 7px;
}

/* Handle on hover */
.search-results::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.search-results{
    overflow-y: scroll;
    overflow-x: hidden;    
}

.location-item {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px rgba(82, 82, 82, 0.25);
    background-color: var(--grayscale-ffffff);
    border-radius: 4px;
    opacity: 1;
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 14px;
    display: grid;
    width: 97%;
}

.location-name{
    margin-bottom: 10px;
    font-family: 'Roboto-Bold';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #215ba6;
}

.subname-matched {
    font-family: 'Roboto-Regular';
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: red;
    margin-top: -10px;
}

.location-info-container{
    width: 100%;
    display: flex;
    margin-bottom: 2px;
}

.location-info b{
    font-family: 'Roboto-Bold';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #292929;
    padding-bottom: 5px;
}
.location-info{
    width: fit-content;
    display: inline-block;
    padding: 10px 20px 0 0;
    font-family: 'Roboto-Regular';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: 0.26px;
    text-align: left;
    color: #525252;
}

.location-info-display{
    display: contents;
}

.location-address {
    width: 30%;
}

.location-type{
    width: 23%;
}

.location-city{
    width: 20%;
}

.location-state{
    width: 12%;
}

.location-zipcode{
    width: 15%;
    padding-right: 0;
}

.location-item:hover{
    border: solid 1px #215BA6;
}

.search-results a:hover{
    text-decoration: none !important;
}

.location-link button{
    border-radius: 20px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #215ba6;
    background-color: var(--grayscale-ffffff);
    font-family: 'Roboto-Bold';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #215ba6;
    height: 30px;
    width: 130px;
}

.location-link button:hover{
    border: solid 1px #0B2F7B;
    color: #0B2F7B;
}

.button-container{
    float: right;
    padding-top: 25px;
    padding-right: 40px;
}

.no-results{
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #292929;
    padding-top: 110px;
}


.no-results-header{
    font-family: 'Roboto-Medium';
    font-size: 28px;
    font-weight: 500;
    line-height: 1.29;
}

.no-results-body b{
    font-family: 'Roboto-Medium';
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
}

.no-results-body{
    font-family: 'Roboto-Regular';
    font-size: 14px;
    line-height: 1.29;
    padding-top: 20px;
}

.hide-box{
    display: none;
}

.spinner-container{
    position: absolute;
    top: 50%;
    width: 100%;
}

.spinner-container .spinner{
    margin:auto;
}

.no-results .btn-cancel{
    padding: 6px !important;
    width: 230px;
    font-size: 14px;
    margin-top: 35px;
}

@media only screen and 
(max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape){

    .search-body, .no-results{
        top: unset;
        position: relative;
        margin-top: 0;
        padding: 0;
        height: calc(100% - 290px);
    }

    .no-results{
        padding: 24px !important;
        overflow-y: auto;
    }

    .no-results-body{
        padding-top: 15px;
    }

    .no-results-body b{
        font-size: 16px;
        line-height: 2;
    }

    .no-results .btn-cancel{
        margin-top: 10px;
    }

    .search-results-header{
        display: none;
    }

    .no-results-header{
        font-size: 18px;
    }

    .search-results-container{
        padding: 10px 24px;
        width: 100%;
    }

    .search-results{
        height: unset;
        max-height: unset;
        overflow: hidden;
    }

    .search-results .location-item:hover, .search-results .location-item:active, .search-results .location-item:focus  {        
        border: solid 1px #68368B !important;
    }

    .location-item{
        margin-left: 0;
        width: 100%;
    }

    .location-info-container{
        display: inline-block;
    }

    .location-info{
        padding-right: 0;
    }

    .location-name{
        color: #68368B;
        margin-bottom: 0;
    }

    .subname-matched{
        font-size: 3.4vmin;
        word-wrap: break-word;
        margin-top: 0;
    }

    .location-info-display{
        display: flex;
    }

    .location-type, .location-address {
        width: 100%;
    }
    
    .location-city{
        width: 40%;
        float: left;
    }
    
    .location-state{
        width: 25%;
    }
    
    .location-zipcode{
        width: 35%;
    }
}

@media only screen and
(max-width: 359px){
    
    .location-name{
        font-size: 14px !important;
    }
}

/* CSS for Mobile landscape and Tablet portrait/landscape view */
@media only screen and
(min-width: 820px) and (max-width: 1023px) and (orientation: portrait), 
(max-width: 1279px) and (orientation: landscape) {
    
    .search-body, .no-results{
        height: calc(100% - 260px);
    }

    .location-name{
        font-size: 15px !important;
    }

    .location-info-container{
        width: 100%;
        display: flex;
        margin-bottom: 2px;
    }

    .location-info-display{
        display: contents;
    }

    .location-type{
        width: 20%;
        min-width: 110px;
    }

    .location-address {
        width: 30%;
    }
    
    .location-city{
        width: 22%;
        float: unset;
    }
    
    .location-state{
        width: 13%;
    }
    
    .location-zipcode{
        width: 15%;
    }
}

@media only screen and (min-width: 1280px){
    .map {
        display: block;
    }
    .search-results-container{
        width: 54%;
    }
    .search-results{
        max-height: 540px;    
    } 
}

@media only screen and 
(min-width: 1280px) and (max-height: 940px) {
    .search-results {
        height: 83%;    
    }
    .map{
        height: 96%;    
    }
}