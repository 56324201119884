.footer {
  background: #F2F3F4;
  height: 55px;
  text-align: center;
  padding: 20px;
  font: normal normal normal 12px/14px Helvetica;
  letter-spacing: 0px;  
  justify-content: center;
  align-content: center;
  margin-top: auto;
  z-index: 2;
}

.footer a{
  color: #48494B;
  margin-left: 1.5%;
}

.footer p{
  color: #48494B;
  margin-bottom: 0;
}


@media only screen and 
(max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape){
  .footer{
      background-color: #292929;
      font-size: 10px;     
  }
  .footer a, .footer p{
    color: #ffffff;
  }
}

@media only screen and 
  (max-width: 1279px) and (orientation: landscape){
    .footer{
      height: 40px;
    }
  }

  @media only screen and 
  (max-width: 428px) and (orientation: portrait){
      .footer{
        display: block;
        padding: 10px;
        line-height: 1rem;
      }
  }