.Login-Container {
  background-color: #06122A;
  width: 100%;
  height: 60vmin;
  min-height: 390px;
  max-height: 590px;
  justify-content: space-between;
  display: inline-flex;
}

.Login {      
    max-width: 50%;    
    align-self: center;
    margin: 0 auto;    
  }

  .Login-Header{
    color: white;
    width: 420px;
    font: normal normal normal 35px/42px Helvetica;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin: auto;
    margin-bottom: 3.2vmin;
  }

  .Login-Form{
    width: 420px;
    margin: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 7px #00000029;
    border-radius: 4px;
    font-family: Roboto-Regular;
    font-size: 14px;
    padding: 4vmin 5vmin;
  }

  .Login-Form .LoaderButton{
    margin-bottom: 3vh;
  }

  .Login .form-group{
    margin-bottom: 27px;
    height: fit-content;
  }

  .Login .form-control{
    font-family: Arial;
    font-size: 14px;
  }

  .Login .form-group input{
    height: 40px;
  }
  
  .Login-Image{
      display: table-cell;
      width: 50%;
      overflow: hidden;
  }

  .Login-Image img{
    height: fill-available;
  }
  
  .new-member-request-container{
    font-family: Roboto-Regular;
    font-size: 14px;
    color: #525252;
  }

  .Login .btn-link{
    font-family: Roboto-Regular;
    font-size: 14px;
    color: #215ba6;
    padding: 10px 0px;
  }

  .new-member-request-container .btn-link{
    padding: 6px;
  }

  .Login .btn-link:focus{
    box-shadow: none;
  }

  @media only screen and 
  (max-width: 1023px) and (orientation: portrait),
  (max-width: 1279px) and (orientation: landscape)
  {
    .Login-Image{
      display: none;
    }

    .Login-Background{
      background-image: url(../images/Login-Page-Image-Mobile.png);
      display: inline-flex;
      justify-content: center;
    }

    .Login-Container{
      margin: 14vh 24px 0 24px;
      padding: 2.3vmax;
      height: fit-content;
      background-color: #FFFFFF;
      border-radius: 4px;
      display: unset;
    }

    .Login{
      padding: 0;
      max-width: 100%;
    }

    .Login-Header{
      width: 100%;
      color: #68368B;
      text-align: left;
      text-transform: uppercase;
      font-size: 18px;
      font-family: 'Roboto-Bold';
      margin-bottom: 5%;
    }

    .Login-Form{
      box-shadow: none;
      -webkit-box-shadow: none;
	    -moz-box-shadow: none;
      padding: 0;
      width: auto;
    }

    .Login-Container .LoaderButton{
      margin-bottom: 20px;
    }

    .new-member-request-container{
      margin: 0;
    }
  }

  @media only screen and 
  (min-width: 480px) and
  (max-width: 1023px) and (orientation: portrait),
  (max-width: 1279px) and (orientation: landscape){
        
    .Login-Background {
      background-image: url(../images/Login-Page-Image-landscape-Mobile.png) !important;
      height: 100%;
    }
    .Login-Container{
      width: 38vmax;
      min-width: 300px;
      margin: 10vh auto;
    }

    .Login-Container .LoaderButton{
        margin-bottom: 5%;
    }

    .Login .form-group {
      margin-bottom: 15px;
    }
  
    @media only screen and 
    (min-height: 1024px) and (orientation: portrait){
      .Login-Background {
        background-image: url(../images/Login-Page-Image-Portrait_Tablet.png) !important;
      }
    }
  
  }

  @media only screen and 
  (max-width: 359px){
    .Login-Header{
      font-size: 4.3vw;
    }
    .Login-Form{
      font-size: 3.4vw !important;
    }
  }