.search-type-toggle-wrapper {
    clear: both;
    display: flex;
    font: normal normal normal 14px/18px 'Roboto-Regular';
    letter-spacing: 0px;
    color: #FFFFFF;
    height: 30px;
    margin-bottom: 15px;
    border: 2px solid #FFFFFF;
    width: fit-content;
    border-radius: 20px;
    background-color: #FFFFFF;
  }
  
  .search-type-toggle {
    position: absolute;
    left: -9999em;
    top: -9999em;
  }
    
    .search-type-toggle + label {
      float: left;
      padding: 5px 10px;
      cursor: pointer;
      margin-right: -1px;
      color: #292929;  
      height: 100%;
    }
    .search-type-toggle + label:first-of-type {
        border-radius: 16px 0 0 16px;
      }
      
      .search-type-toggle + label:last-of-type {
        border-radius: 0 16px 16px 0;
      }  

    
    .search-type-toggle:checked + label {
      background-color: #215BA6;
      border: 1px solid #215BA6;
      color: #FFFFFF;
      border-radius: 20px;
    }

    /* Customize the label (the container) */
.filter-check-container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font: normal normal 300 14px 'Roboto-Regular';
    color:#292929;
    
  }
  
  /* Hide the browser's default checkbox */
  .filter-check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .filter-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 2px solid #292929;
  }
  
  /* When the checkbox is checked, add a blue background */
  .filter-check-container input:checked ~ .filter-checkmark {
    background-color: #292929;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .filter-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .filter-check-container input:checked ~ .filter-checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .filter-check-container .filter-checkmark:after {
    left: 3px;
    top: -1px;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }