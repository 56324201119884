.location-details-mobile-view{
    background-color: #ffffff;
    height: 100%;
}

.header {
    height: 40px;
    padding: 10px 0 9px 24px;
    background-color: #68368b;
    object-fit: contain;
    font-family: Roboto-Bold;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
}

.mobile-details-container {
    margin: 10px 24px;
    padding: 10px 20px 12px 12px;
    object-fit: contain;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px rgba(82, 82, 82, 0.25);
}

.MuiTab-wrapper {
    font-family: Roboto-Bold !important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #292929;
}

.word-cloud-mobile-label {
    color: #68368b;
    font-family: Roboto-Bold;
    font-size: 18px;
}

.word-cloud-mobile-child {
    display: inline-flex;
    border-radius: 9px;
    border: solid 1px #c8c8c8;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 10px 15px;
}

.word-cloud-mobile-main {
    justify-content: space-between;
    width: 100%;
    padding: 28px;
}

/* .word-cloud-mobile-section{
    background-color: #FFFFFF;
} */

.mobile-word {
    font-family: Roboto-Medium;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.24px;
    text-align: left;
    color: #6e6d6a;
}

.mobile-count {
    font-family: Roboto-Bold;
    font-size: 10px;
    color: #292929;
}

.edits-link{
    height: 14px;
    margin-right: 35px;
    margin-top: -27px;
    color: rgb(104, 54, 139);
    font-family: Roboto-Bold;
    font-size: 14px;
    float: right;
}

.edits-link:hover{
    color: #482659;
    text-decoration: unset;
}