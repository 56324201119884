.geofence-map-container {
    font-family: sans-serif;
    /* text-align: center; */
  }
  .editable-map {
    height: 350px;
    width: 100%;
  }
  
  .tooltip {
    z-index: 1;
    position: absolute;
    color: #a0a7b4;
    background-color: #29323c;
    padding: 0.2rem 2rem;
    border-radius: 4px;
    font-size: 10px;
  }