.time-picker-floating-label {
  font-size: 10px;
  color: #C8C8C8;
  font-weight: 400;
  position: absolute;
  top: calc(50% - 30px);
  transition: all .3s ease;
  width: -moz-fit-content;
  padding: 0 2px;
  width: fit-content;
  background-color: #FDFDFD;
  height: fit-content;
  margin: 0 0 0 15px;
  max-height: 18px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  font-size: 13px;
}


input[type="time"] {
  display: flex;
  position: relative;
  min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 7px 15px 3px 15px;
  box-sizing: content-box;
  border: solid 1px #C8C8C8;
  border-radius: 4px;
  width: 106px;
  align-items: center;
  word-spacing: 1px;
  letter-spacing: 0.5px;
  
}

input[type="time"]:focus {  
  border-color: #215BA6 !important;
}