.services-container .radio-label {
    font-size: 16px;
}

.services-container .edit-details-body-padding{
    padding-top: 4%;
}

.services-container .label-description{
    color: #000000DE;
}

.services-container input[type="text"]:disabled, .services-container select:disabled{
    background-color: #E2E2E2;
}