.edit-header{
    font: normal normal normal 18px Roboto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 2vh 4vmax;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid #ced4da;
}

.edit-title{
    font-size: 24px;
    font-weight: 300;
    width: 72%;
}

.edit-body-title{
    display: inline-flex;
    padding-left: 0.2%;
    width: 100%;
}

.checkbox-margin-bottom{
    margin-bottom: 2%;
}

@media only screen and (min-width: 1920px) {
    .edit-header{
        padding: 2vh 11%;
    }

    .edit-container-body{
        column-gap: 45px;
    }
}

@media only screen and (min-width: 1280px) {
    .services-container .floating-label{
        width: 30vmin;
    }
}