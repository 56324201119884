.MainLayout .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .MainLayout {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    max-width: 100%;
    padding: 0!important;
    height: calc(100% - 65px);
    height: -webkit-calc(100% - 65px);
    height: -moz-calc(100% - 65px);
  }

  .main-layout-header-image-mobile{
    height: 80px;
    width: 100%;
    display: flex;
  }

  .Main-Layout-Body {
    background: #F2F3F4 0% 0% no-repeat padding-box;
    height: -webkit-calc(100% - 80px);
    height: -moz-calc(100% - 80px);
    height: calc(100% - 80px);
  }

  .right-align-nav {
    margin-left: auto;
    margin-right: 75px;
  }  

  .user-icon{
    background-color: #215BA6 !important;
    height: 22px !important;
    width: 22px !important;
  }

  .inline-dropdown {
    display: inline-flex;
  }

@media only screen and 
(max-width: 1023px) and (orientation: portrait),
(min-width: 568px) and (max-width: 1279px) and (orientation: landscape)
{  
  .Main-Layout-Body-scroll::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  .Main-Layout-Body-scroll::-webkit-scrollbar-track {
      margin-top: 10px;
      margin-bottom: 20px;
  }
      
  /* Handle */
  .Main-Layout-Body-scroll::-webkit-scrollbar-thumb {
      background: #68368B;
      border-radius: 20px;
  }

  .Main-Layout-Body {
    margin: 0;
    height: calc(100% - 41vmin);
    background-color: #fff;
  }
  
  .right-align-nav {
    display: none;
  }

  .main-layout-header-image-mobile img{
      width: 100%;
      height: inherit;
  }

  .header-image-mobile-portrait, .header-image-mobile-landscape, .header-image-tablet-portrait, .header-image-tablet-landscape{
    display: none;
  }
}

/* CSS for Mobile Portrait view */
@media only screen and  
(max-width: 428px) and (orientation: portrait){
  .header-image-mobile-portrait{
    display: block !important;
  }
}

/* CSS for Mobile Landscape view */
@media only screen and  
(max-width: 926px) and (orientation: landscape) {

  .header-image-mobile-landscape{
    display: block !important;
  }

  .main-layout-header-image-mobile{
    height: 60px;
  }

  .Main-Layout-Body {
    height: calc(100% - 110px) !important;
  }
}

/* CSS for Tablet Portrait view */
@media only screen and  
(min-width: 429px) and (max-width: 1023px) and (orientation: portrait){
    
    .header-image-tablet-portrait{
      display: block !important;
    }

    .main-layout-header-image-mobile{
      height: 150px;
    }
}

/* CSS for Tablet landscape view */
@media only screen and  
(min-width: 1024px) and (max-width: 1279px) and (orientation: landscape) {

    .header-image-mobile-portrait{
      display: none !important;
    }

    .header-image-tablet-landscape{
      display: block !important;
    }
}