.appointment-checkbox-container .option-checkmark{
    width: 18px;
    height: 18px;
    margin-right: 2%;
}

.visible-container{
    display: block;
}

.hide-container{
    display: none;
}

.appointment-checkbox-container{
    margin: 2% 0% 3% 0.5%;
}

.appointment-checkbox-container .option-check-container{
    font-size: 16px;
    letter-spacing: 0.32px;
    padding-left: 28px;
    margin-bottom: 2.5%;
    width: fit-content;
}

.appointment-checkbox-container .option-check-container .option-checkmark:after {
    left: 3px;
    top: -1px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.appointment-checkbox-container .floating-label{
    height: 40px !important;
    margin-bottom: 0px !important;
}

.edits-appointment-container .floating-label input:focus, .edits-appointment-container .floating-label select:focus, .edits-appointment-container .floating-label textarea:focus{
    border-color: #007EB5;
}

.edits-appointment-container .form-control:disabled, .form-control[readonly] {
    background-color: #C8C8C8;
    border-color: #C8C8C8;
    opacity: 1;
}