.AuthLayout {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    max-width: 100%;
    padding: 0!important;
}

.Auth-Layout-Body {
    margin-top: 4.8vmax;
    margin-bottom: 4.5vmax;
}

@media only screen and 
(max-width: 1023px) and (orientation: portrait),
(max-width: 1279px) and (orientation: landscape) {

    .login-nav .navbar-toggler{
        display: none !important;
    }
    
    .AuthLayout{
        height: calc(100% - 55px);
    }

    .Auth-Layout-Body {
        margin: 0;
        height: calc(100% - 50px);
        display: grid;
    }

    @media only screen and 
    (max-width: 1279px) and (orientation: landscape){
        .AuthLayout{
            height: calc(100% - 40px);
        }
    }
}