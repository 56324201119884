.contacts-container{
    width: 27vmin;
    margin-bottom: 35px;
    text-align: right;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0.4px;
    color: #838383;
    opacity: 1;
    height: 35px;
}

.contact-type-dropdown {
    padding-bottom: 30px;
}

.contacts-container .floating-label{
    height: 100% !important;
}